import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userCardsContainer: {
      maxWidth: '100%',
      minWidth: 270,
      height: 720,
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      gap: 14,
      padding: 7,
      marginTop: 14,
      marginBottom: 24,
    },
    userCard: {
      width: 256,
      height: 'fit-content',
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 12,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderWidth: 1,
      border: 'solid',
      borderColor: '#CAC4D0',
      display: 'flex',
      flexDirection: 'column',
    },
    userPhotoContainer: {
      height: 120,
      display: 'flex',
      justifyContent: 'center'
    },
    userPhoto: {
      width: 120,
      height: 120,
      borderRadius: 120,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    userInfoContainer: {
      minHeight: 201,
      padding: 16,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    userFullName: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey6,
      fontWeight: 700
    },
    userJobPosition: {
      ...theme.typography.txtBody1,
      color: '#49454F',
      lineHeight: '20px',
      letterSpacing: '0.25px'
    },
    servicesList: {
      marginTop: 16,
      marginBottom: 16,
    },
    serviceName: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      lineHeight: '18px'
    },
    moreServicesButton: {
      ...theme.typography.txtBody2,
      color: theme.colors.clearMain,
      fontWeight: 700,
      cursor: 'pointer'
    },
    viewButtonContainer: {
      width: '100%',
      height: 32,
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
    },
    viewButton: {
      ...theme.typography.buttons,
      fontWeight: 700,
      color: theme.colors.clearMain,
      width: 168,
      height: 32,
      borderRadius: 5,
      paddingTop: 11,
      paddingBottom: 11,
      paddingLeft: 16,
      paddingRight: 16,
      border: 'solid',
      borderWidth: 1,
      borderColor: theme.colors.clearMain,
      justifyContent: 'space-around',
      "&.Mui-disabled": {
        color: theme.colors.clearMain,
      },
      [theme.breakpoints.down("sm")]: {
        width: 163
      },
    },
    photoInitials: {
      ...theme.typography.header1,
      color: theme.colors.clear2,
      fontWeight: 700,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 120,
      height: 120,
      border: "solid",
      borderWidth: 1,
      borderColor: theme.colors.clear2,
      borderRadius: "50%",
    },
  })
);

export default useStyles;

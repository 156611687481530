import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      position: 'fixed',
      top: 65,
      right: 20,
      width: 343,
      borderRadius: 10,
      backgroundColor: theme.colors.white,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.10)',
      zIndex: 1200,
      overflowY: 'auto',
      maxHeight: 660,

      [theme.breakpoints.down("sm")]: {
        position: 'fixed',
        top: 'auto',
        bottom: 0,
        right: 0,
        width: '100%',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    overlay: {
      [theme.breakpoints.down("sm")]: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(50, 57, 71, 0.76)',
        zIndex: 999,
      },
    },
    modalLeftPanel: {
      width: 5,
      background: theme.colors.clearMain,
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    modalLeftPanelContainer: {
      display: 'flex',
      flexDirection: 'row'
    },
    modalContentWrapper: {
      width: '100%',
    },
    modalClosePanel: {
      background: theme.colors.grey1,
      height: 18,
      borderTopRightRadius: 10,
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 12,
      paddingRight: 12,
      alignItems: 'center',
    },
    modalDragContainer: {
      width: 290,
      display: 'flex',
      alignItems: 'center',
      cursor: 'move',
      [theme.breakpoints.down("sm")]: {
        display: 'none',      
      },
    },
    closeIcon: {
      color: theme.colors.grey4,
      cursor: 'pointer',
      [theme.breakpoints.down("sm")]: {
        marginLeft: '97%',
      },
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 14,
    },
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.grey6,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
    },
    cartIcon: {
      marginRight: 12,
    },
    modalContentContainer: {
      padding: 10
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      marginBottom: 24,
    },
    serviceInfo: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.colors.white,
    },
    serviceInfoText: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    serviceName: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey6,
      fontWeight: 700,
    },
    serviceDuration: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5
    },
    servicePrice: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
      position: 'absolute',
      right: 12
    },
    totalContent: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 24,
      color: theme.colors.grey6,
      fontFamily: 'Roboto',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '16px',
    },
    totalPrice: {
      color: theme.colors.grey6,
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '16px',
    },
    modalActions: {
      display: 'flex',
      justifyContent: 'center',
    },
    scheduleButton: {
      ...theme.typography.buttons,
      fontWeight: 700,
      backgroundColor: theme.colors.clearMain,
      color: theme.colors.white,
      width: 186,
      height: 32,
      borderRadius: 10,
      '&:hover': {
        backgroundColor: theme.colors.clear2,
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    draggableHandle: {
      cursor: 'move',
    },
  })
);

export default useStyles;

import React from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { BookMarkIcon, CalendarIcon, ClockIcon, MoneyIcon, UserIcon, } from "./../../../assets/icons";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import useStyles from "./css";
import { Tooltip } from "@material-ui/core";

export default function AppointmentSummary() {
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const serviceUser = useAppSelector((state) => state.scheduler.serviceUser);
  const isValidSelection = useAppSelector((state) => state.scheduler.isValidSelection);
  const localeApp = useAppSelector(state => state.scheduler.locale);
  const currentDate = useAppSelector((state) => state.scheduler.currentDate);
  const scheduleTime = useAppSelector((state) => state.scheduler.schedule);
  const account = useAppSelector((state) => state.scheduler.account);
  const isServicesPerformedSimultaneously = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);
  
  const firstService = serviceUser[0]?.service;
  
  function countAppointmentDuration(services: ServiceUserModel[]) {
    if (isServicesPerformedSimultaneously) {
      return Math.max(...services.map(service => service?.service?.duration || 0));
    } else {
      return services.reduce((previousValue, currentValue) => previousValue + (currentValue?.service?.duration ? currentValue.service.duration : 0), 0);
    }
  }
  
  function countAppointmentPrice(services: ServiceUserModel[]) {
    return services.reduce((previousValue, currentValue) => previousValue + (currentValue?.service?.price ? currentValue.service.price : 0), 0);
  }
  
  const appointmentPrice = countAppointmentPrice(serviceUser);

  const getDuration = (): string => {    
    const appointmentDuration = countAppointmentDuration(serviceUser);
    if (appointmentDuration === 0) {
      return "";
    }
    
    if (appointmentDuration < 60) return `${appointmentDuration} ${t("minutes")}`;
    
    const seconds = appointmentDuration * 60;
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);
    const hourDisplay = hour > 0 ? `${hour} ${hour === 1 ? "hr" : "hrs"} ` : "";
    const minuteDisplay = minute > 0 ? `${minute} min` : "";
    const format = hourDisplay + minuteDisplay;
    
    return `${appointmentDuration} ${t("minutes")} (${format.trim()})`;
  };

  const formatDate = (): string => {
    const date = new Date(currentDate);
    return date.toLocaleDateString(localeApp, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };
  
  const specialists = serviceUser.map((s: ServiceUserModel) => {
    const specialist = s.users?.find(u => u.id === s.userId);
    return s.service && (specialist || s.userId == "All")
      ?
      specialist
        ? `\u2022 ${specialist?.firstName} ${specialist?.lastName}`
        : `\u2022 ${t("Any specialist")}`
      : ""
  })

  const areThereMultipleServices = serviceUser.length > 1;

  return (<>
    <Grid container spacing={0} className={classes.root} >
      {
        firstService?.photo && <>
          <Grid item xs={12}>
            <Box
              className={classes.serviceImage}
              style={{ backgroundImage: `url("${firstService?.photo}")`}}
            ></Box>
          </Grid>
          </>
      }
      <Grid container spacing={0} className={classes.boxContainer}>
        <Grid item xs={12}>
          <Box
            className={classes.boxContent}
          >
            <div className={classes.boxContentItem}>
              <Grid className={classes.boxContentItemLabel} container spacing={0}>
                <BookMarkIcon style={{ width: 8, height: 14, marginRight: 4 }} />
                <Typography className={classes.label}>{t(areThereMultipleServices ? "Service(s)" : "Service")}</Typography>
              </Grid>
              <Grid container direction={"column"} spacing={0}>
                {
                  serviceUser.map((s: ServiceUserModel) => s.service?.name
                    ?
                    <Tooltip
                      title={<span>{`\u2022 ${s.service.categoryName} \u00B7 ${s.service.name}`} </span>}
                      classes={{ tooltip: classes.tooltipBlock }}
                    >
                      <Typography
                        className={classes.caption}
                      >
                        {`\u2022 ${s.service.categoryName} \u00B7 ${s.service.name}`}
                      </Typography>
                    </Tooltip>
                    : "")
                }
              </Grid>
            </div>
            <div className={classes.boxContentItem}>
              <Grid className={classes.boxContentItemLabel} container spacing={0}>
                <ClockIcon style={{ width: 10, height: 10, marginRight: 2 }} />
                <Typography className={classes.label}>{t("Duration")}</Typography>
              </Grid>
              <Grid container spacing={0}>
                <Typography
                  className={classes.caption}
                >
                  {getDuration()}
                </Typography>
              </Grid>
            </div>

            {account.showPrices && <div className={classes.boxContentItem}>
                <Grid className={classes.boxContentItemLabel} container spacing={0}>
                    <Typography className={classes.symbol}>{account.currencySymbol}</Typography>
                    <Typography className={classes.label}>{t("Price")}</Typography>
                </Grid>
                <Grid container spacing={0}>
                    <Typography
                        className={classes.caption}
                    >
                      {appointmentPrice > 0 ? `${appointmentPrice}` : ""}
                    </Typography>
                </Grid>
            </div>}

          </Box>
          <div className={classes.divider} />
          <Box className={classes.boxContent}>
            <div className={classes.boxContentItem}>
              <Grid className={classes.boxContentItemLabel} container spacing={0}>
                <UserIcon style={{ width: 9, height: 9, marginRight: 3 }} />
                <Typography className={classes.label}>
                  {t(areThereMultipleServices ? "Specialist(s)" : "Specialist")}
                </Typography>
              </Grid>
              <Grid container direction={"column"} spacing={0}>
                {
                  specialists.map((specialistName: string) =>
                    <Typography
                      className={classes.caption}
                    >
                      {specialistName}
                    </Typography>
                  )
                }
              </Grid>
            </div>
          </Box>
          <div className={classes.divider} />
          <Box className={classes.boxContent}>
            <div className={classes.boxContentItem}>
              <Grid className={classes.boxContentItemLabel} container spacing={0}>
                <CalendarIcon style={{ width: 9, height: 10, marginRight: 3 }} />
                <Typography className={classes.label}>
                  {t("Date")}
                </Typography>
              </Grid>
              <Grid container spacing={0}>
                <Typography
                  className={classes.caption}
                >
                  {serviceUser.length && isValidSelection ? formatDate() : ""}
                </Typography>
              </Grid>
            </div>
            <div className={classes.boxContentItem}>
              <Grid className={classes.boxContentItemLabel}
                container
                spacing={0}
              >
                <ClockIcon style={{ width: 10, height: 10, marginRight: 2 }} />
                <Typography className={classes.label}>
                  {t("Time")}
                </Typography>
              </Grid>
              <Grid className={classes.boxContentItemLabel} container spacing={0}>
                <Typography
                  className={classes.caption}
                >
                  {serviceUser.length && isValidSelection ? scheduleTime : ""}
                </Typography>
              </Grid>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>

  </>);
};

import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import MaskedInput from "react-text-mask";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { SortDownIcon } from "./../../../assets/icons";
import CountryFlagIcon from "./../../../assets/flags";
import Country from "../../../models/common/country";
import { AppointmentService } from "../../../api";
import { GetMobileMaskByTemp } from "../../../constants/masks";
import { InputMobileCodeProps } from "./props";
import useStyles from "./css";
import { schedulerActions } from "../../../redux/scheduler-slice";
import SearchIcon from "../../../assets/icons/SearchIcon";
import Input from "../Input";


export default function InputMobileCode(props: InputMobileCodeProps) {
  
  const dialTemplate: string = "000-000-0000";
  
  const {
    label,
    labelClassName,
    widthSelect,
    widthList,
    isOptional,
    codeIso3,
    dialDigits,
    mobile,
    isValid,
    onChangeItem,
    onChangeMobile,
    onChangeComplete,
    onBlur
  } = props;
  
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation(["general"]);
  const dispatch = useAppDispatch();
  const classes = useStyles(widthSelect || 350, widthList || 350,  isMdMedia)();
  const localeApp = useAppSelector(state => state.scheduler.locale);
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const [countries, setCountries] = useState<Country[]>([]);
  const [countrySelected, setCountrySelected] = useState<Country>(new Country({
    iso3: "MEX", dialCode: 52, dialDigits: 10, dialTemplate: dialTemplate, name: "México"
  }));
  const [mobileNumber, setMobileNumber] = useState<string>(mobile);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [masking, setMasking] = useState<any>(GetMobileMaskByTemp(dialTemplate));
  const [isValidNumber, setIsValidNumber] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  
  const account = useAppSelector((state) => state.scheduler.account);
  
  const { setShowLoader } = schedulerActions;
  
  // const isEditable = useAppSelector((state) => state.scheduler.isEditable); // fbn
  
  function handleItemSelected(item: Country) {
    setSearchValue(null);
    let country = new Country({
      iso3: item.iso3,
      name: item.name,
      dialCode: item.dialCode,
      dialDigits: item.dialDigits,
      dialTemplate: item.dialTemplate
    });
    
    setCountrySelected(country);
    setOpenModal(false);
    
    let newNumber = mobileNumber;
    if (newNumber) {
      if (country.iso3 === "ECU" && newNumber.startsWith("0")) {
        newNumber = newNumber.replace(/^0+/, "");
      }
      newNumber = newNumber.substring(0, country.dialDigits || 10);
      setMobileNumber(newNumber);
    }
    
    const m = GetMobileMaskByTemp(country.dialTemplate || dialTemplate);
    setMasking(m);
    
    if (onChangeItem) onChangeItem(country);
    
    const valid = validate(newNumber, country.dialDigits, country.iso3);
    if (onChangeComplete) onChangeComplete(country.iso3, newNumber, valid);
  }
  
  
  function handleSearch(searchValue: string) {
    setSearchValue(searchValue);
  }
  
  function handleMobileChange(event: React.ChangeEvent<{ value: string }>) {
    const mobile = event.target.value.replace(/[^0-9]/g, "");
    
    setMobileNumber(mobile);
    if (onChangeMobile) onChangeMobile(mobile, countrySelected);
    
    
    const valid = validate(mobile, dialDigits ?? countrySelected?.dialDigits, countrySelected.iso3);
    if (onChangeComplete) onChangeComplete(countrySelected.iso3, mobile, valid);
  }
  
  function filterCountries (item: Country) {
    const lowercaseSearchValue = searchValue!.toLowerCase();
    let isSearchByDialCode = /^\+?\d+$/.test(searchValue!);
    
    if (searchValue!.trim().length < 2 && !isSearchByDialCode) {
      return true;
    }
    
    return (
      item.name.toLowerCase().includes(lowercaseSearchValue) ||
      item.dialCode.toString().startsWith(lowercaseSearchValue.replace("+", ""))
    );
  }
  
  function validate(mobileComplete: string, digits: number, iso3?: string): boolean {
    let validDig = false;
    
    if (mobileComplete) {
      const mobile = mobileComplete.replace(/[^0-9]/g, "");
      validDig = (digits === 7 && /^\d{7}$/.test(mobile!)) ||
        (digits === 8 && /^\d{8}$/.test(mobile!)) ||
        (digits === 9 && (iso3 === "ECU" ? /^[1-9]\d{8}$/ : /^\d{9}$/).test(mobile!)) ||
        (digits === 10 && /^\d{10}$/.test(mobile!)) ||
        (digits === 11 && /^\d{11}$/.test(mobile!)) ||
        (digits === 12 && /^\d{12}$/.test(mobile!));
      
      setIsValidNumber(validDig);
      if (isValid) isValid(validDig);
    }
    
    setIsValidNumber(validDig);
    if (isValid) isValid(validDig);
    return validDig;
  }
  
  useEffect(() => {
    
    (async () => {
      dispatch(setShowLoader(true));
      const response = await AppointmentService.getCountries(account.id, localeApp);
      dispatch(setShowLoader(false));
      
      const listCountries: Country[] = response.country;
      const countryInSettings: Country = response.countryInSettings;
      
      setCountries(listCountries || []);
      // if (onChangeItem) onChangeItem(countryInSettings);
      
      let iso = codeIso3 || (countryInSettings ? countryInSettings.iso3 : null) || "MEX";
      
      const countryByIso = listCountries.filter(x => x.iso3 === iso);
      
      if (countryByIso.length > 0) {
        const country = countryByIso[0];
        setCountrySelected(country);
        const m = GetMobileMaskByTemp(country.dialTemplate || dialTemplate);
        setMasking(m);
      }
      
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeIso3]);
  
  
  useEffect(() => {
    const m = GetMobileMaskByTemp(countrySelected.dialTemplate);
    setMasking(m);
    setMobileNumber(mobile);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobile]);
  
  function handleOpenModal() {
    setOpenModal(true);
  }
  
  function handleCloseModal() {
    setSearchValue(null);
    setOpenModal(false);
  }
  
  return (
    <div className={classes.component}>
      <div className={classes.divDropdown}>
        <label className={classes.labelCountry}>
          <Typography className={labelClassName || classes.labelCountry}>
            {label || t("Mobile") + " "}
            {(isOptional ?? true) ?
              <span className={classes.optional}>({t("Optional")})</span>
              :
              <span className={classes.required}> *</span>
            }
          </Typography>
        </label>
        
        <div className={`${classes.inputGroup} ${!isValidNumber ? classes.inputError : ""}  ${isFocused ? classes.inputFocused : ''}`}>
          <div className={classes.divCodes} onClick={handleOpenModal}>
            <div className={classes.inputFlag}>
              <CountryFlagIcon iso={countrySelected?.iso3 || "MEX"}/>
            </div>
            <div className={classes.iconDropdown}>
              <SortDownIcon/>
            </div>
            <div className={classes.divDialCode}>
              {countrySelected.dialCode !== undefined ? `+${countrySelected.dialCode}` : "+52"}
            </div>
          </div>
          <MaskedInput
            className={`${classes.inputClassic} ${classes.inputSelectBtn} `}
            placeholder={countrySelected?.dialTemplate || dialTemplate}
            mask={masking}
            placeholderChar=" "
            onChange={handleMobileChange}
            onBlur={() => {
              setIsFocused(false)
              if (onBlur) onBlur(validate(mobileNumber, countrySelected?.dialDigits, countrySelected?.iso3))
            }}
            value={mobileNumber}
            onFocus={() => setIsFocused(true)}
          />
          
        </div>
      </div>
      
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock={true}
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disablePortal={true}
        style={{ position: "relative", top: -20 }}
      >
        <div className={classes.divModalSelect}>
          <div className={classes.searchInputContainer}>
            <div className={classes.titleCountries}>{t("Countries")}</div>
            <Input
              placeholder={t("Search country code")}
              width={"-webkit-fill-available"}
              onChange={(event) => handleSearch(event.target.value)}
            />
            <div className={classes.searchIconContainer}><SearchIcon style={{ fontSize: 17, color: "#919CA5" }}/></div>
          </div>
          <div className={classes.listCountryScroll}>
            {(searchValue === null
              ? countries
              : countries.filter((item) => filterCountries(item)))
              .map((item) => (
                <div key={item.iso3} className={classes.listCountry}>
                  <div className={`${classes.elementList} ${classes.classRow}`}
                       onClick={() => handleItemSelected(item)}>
                                <span style={{ marginRight: 10 }}>
                                    <CountryFlagIcon iso={item.iso3}/>
                                </span>
                    <div className={classes.countryName}>
                      {item.name} (+{item.dialCode})
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
import { ApiClient } from "./api-client";
import { UserModel } from "../models/user";
import { AccountInfoModel } from "../models/account";
import { GroupServiceModel, ServiceModel } from "../models/service";
import {
  AppointmentDetailDtoModel,
  AppointmentDto,
} from "../models/appointment";
import { ScheduleAppointment, ScheduleTime } from "../models/schedule";
import { ServiceUserModel } from "../models/service/service-user.model";
import {ServiceWithUsersModel} from "../models/service/service-with-users-model";
import CustomerInfo from "../models/customer/customer-info";


export class AppointmentService {
  private static baseUrl: string = "/CustomerSite";

  private static isoDate(date: Date): string {
    date = new Date(date);
    const year = date.toLocaleString("en-US", {
      year: "numeric",
    });
    const month = date.toLocaleString("en-US", {
      month: "2-digit",
    });
    const day = date.toLocaleString("en-US", {
      day: "2-digit",
    });

    return `${year}-${month}-${day}`;
  }

  private static handleTime(date: Date): string {
    date = new Date(date);
    const time = date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });

    return `${this.isoDate(date)}T${time}`;
  }

  static async getByBusinessName(
    businessName: string
  ): Promise<AccountInfoModel> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetAccountByBusinessNameUrl?businessNameUrl=${businessName}`
    );

    return new AccountInfoModel(data);
  }

  static async getServicesGroupByCategory(accountId: string): Promise<GroupServiceModel[]> {
    const data = await ApiClient.get(
      `${this.baseUrl}/getservicesgroupbycategory?accountId=${accountId}`
    );

    return data.services.map((service: any) => new GroupServiceModel(service));
  }
  
  static async getServices(
    accountId: string,
    value?: string,
    properties?: string[],
  ): Promise<ServiceModel[]> {
    
    let urlEndpoint = `${this.baseUrl}/getservices?accountId=${accountId}`;
    
    if (value && properties && properties.length > 0) {
      urlEndpoint += `&value=${value}`;
      for (const property of properties) {
        urlEndpoint += `&Properties=${property}`;
      }
    }
    
    return ApiClient.get(urlEndpoint)
      .then((data) => {
        
        let services: ServiceModel[] = [];
        const list = data.services;
        
        list.forEach((data: any) => {
          services.push(data);
        });
        
        return services;
        
      });
  }

  static async getUsersByServiceId(
    accountId: string | null,
    serviceId: string
  ): Promise<UserModel[]> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetUsersByServiceId?accountId=${accountId ?? ""}&serviceId=${serviceId}`
    );

    return data.users.map((user: any) => new UserModel(user));
  }

  static async getUsersForServices(
      accountId: string | null,
      serviceIds: string[]
  ): Promise<ServiceWithUsersModel[]> {
    return  await ApiClient.post(
        `${this.baseUrl}/GetUsersForServices`, { accountId, serviceIds }
    ).then(data => data.serviceUsers);
  }

  static async getAvailableHours(
    accountId: string,
    services: ServiceUserModel[],
    startDate: Date,
    isServicesPerformedSimultaneously: boolean,
    appointmentId?: string
  ): Promise<ScheduleTime[]> {

    if (!accountId || services.length===0) return [];

    const actualServices = services.map(service => {
      return {
        serviceId: service.serviceId,
        userId: service.userId === "All" ? null : service.userId
      }
    })
    
    return ApiClient.post(`${this.baseUrl}/GetAvailableHours`, {
      accountId,
      startDate: this.isoDate(startDate),
      isServicesPerformedSimultaneously,
      appointmentId,
      services: actualServices,
    }).then(data => { return data.scheduleTimes.map((schedule: any) => new ScheduleTime(schedule)) })
  }

  static async createAppointment(
    appointment: ScheduleAppointment
  ): Promise<{ appointment: AppointmentDto | null, isScheduleBusy: boolean, isCustomerBlocked: boolean }> {

    let services: ServiceUserModel[] = [];

    appointment.services?.forEach(item => {
      services.push(new ServiceUserModel({
        serviceId: item.serviceId,
        userId: item.userId === "All" ? null : item.userId
      }))
    })
    
    const newAppointment: ScheduleAppointment = {...appointment, services};

    const data = await ApiClient.post(`${this.baseUrl}/CreateAppointment`, {
      ...newAppointment,
      startDate: this.handleTime(appointment.startDate as Date),
    });

    return {
      appointment: data.appointment !== null ? new AppointmentDto(data.appointment) : null,
      isScheduleBusy: data.isScheduleBusy,
      isCustomerBlocked: data.isCustomerBlocked
    };

  }
  
  static async getProtectedCustomerInfo(protectedCustomerId: string): Promise<CustomerInfo> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetProtectedCustomerInfo?protectedCustomerId=${protectedCustomerId}`
    );
    
    return new CustomerInfo(data.customerInfo);
  }

  static async updateAppointment(
    appointment: ScheduleAppointment
  ): Promise<{ appointment: AppointmentDto | null, isScheduleBusy: boolean, isCustomerBlocked: boolean }> {
    let services: ServiceUserModel[] = [];

    appointment.services?.forEach(item => {
      services.push(new ServiceUserModel({
        serviceId: item.serviceId,
        userId: item.userId === "All" ? null : item.userId
      }))
    })

    const newAppointment: ScheduleAppointment = {...appointment, services};

    const data = await ApiClient.put(`${this.baseUrl}/UpdateAppointment`, {
      ...newAppointment,
      startDate: this.handleTime(appointment.startDate as Date),
    });
    
    return {
      appointment: data.appointment !== null ? new AppointmentDto(data.appointment) : null,
      isScheduleBusy: data.isScheduleBusy,
      isCustomerBlocked: false
    };
  }

  static removeAppointment(appointmentId: string, allRecurringAppointments: boolean = false): Promise<boolean> {

    let endpointUrl = `${this.baseUrl}/DeleteAppointment?appointmentId=${appointmentId}`

    if (allRecurringAppointments)
      endpointUrl += `&allRecurringAppointments=${allRecurringAppointments}`;

    return ApiClient.remove(endpointUrl);
  }

  static async getAppointmentByEncryptedAppointmentId(
    accountId: string,
    encryptedAppointmentId: string
  ): Promise<AppointmentDetailDtoModel> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetAppointmentByEncryptedId?accountId=${accountId}&encryptedAppointmentId=${encryptedAppointmentId}`
    );

    return new AppointmentDetailDtoModel({
      ...data.appointment,
      isEditable: data.isEditable,
    });
  }

  static getCountries(accountId: string | null, locale: string | null): Promise<any> {
    locale = locale ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es'
    let urlEndpoint = `${this.baseUrl}/GetCountries?locale=${locale}&accountId=${accountId}`;
    return ApiClient.get(urlEndpoint)
        .then((data) => {
            return ({country: data.country, countryInSettings: data.countryInSettings });
        });
  }
}

export class UserModel {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    jobPosition?: string;
    photoURL?: string;
  
    constructor(x?: any) {
      if (typeof x === "object") {
        this.id = x.id;
        this.firstName = x.firstName;
        this.lastName = x.lastName;
        this.jobPosition = x.jobPosition;
        this.photoURL = x.photoURL;
      } else {
        this.id = null;
        this.firstName = null;
        this.lastName = null;
        this.jobPosition = undefined;
        this.photoURL = undefined;
      }
    }
  }
  
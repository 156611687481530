import React from "react";

import { useTranslation } from "react-i18next";

import useStyles from "./css";
import { UserCertificaitionsProps } from "./props";
import { UserWithServiceCertifications } from "../../../../models/user/user-with-services-certifications";
import UserCard from "../UserCard";


export default function UserCertifications(props: UserCertificaitionsProps) {

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const { usersWithCertifications, handleCaruselOpen } = props;

  function renderUserCard(userWithCerts: UserWithServiceCertifications) {
    if (!userWithCerts) return;

    return (
      <UserCard
        userWithCertifications={userWithCerts}
        handleCaruselOpen={handleCaruselOpen}
      />
    );
  }

  return (
    <>
      <div className={classes.userCardsContainer}>
        {usersWithCertifications && usersWithCertifications.map(userWithCerts =>
          renderUserCard(userWithCerts)
        )}
      </div>
    </>
  );
}
import React from "react";

import { useTranslation, Trans } from "react-i18next";


import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import useStyles from "./css";
import { useTheme } from "@material-ui/core/styles";
import { ReactComponent as CanNotSchedule } from "../../../assets/images/can-not-schedule.svg";


export default function ScheduleAppointmentFailedStep() {
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {/* Desktop Begin */}
        <div className={classes.leftSideImage}/>
        <div className={classes.appntInfoContainer}>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <CanNotSchedule />
          </div>
          <div className={classes.dividerTop}/>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <Typography className={classes.brandingTitle}>
              {t("Oops! We can't schedule your appointment")}
            </Typography>
          </div>
          <div className={classes.rowCenter} style={{ marginTop: 16 }}>
            <Typography className={classes.text}>
              {t("At the moment, we do not have availability.")}
            </Typography>
          </div>
          <div className={classes.rowCenter}>
            <Typography className={classes.text}>
              {t("We appreciate your patience.")}
            </Typography>
          </div>
        </div>
        {/* Desktop End */}
      </div>
    </div>
  );
}
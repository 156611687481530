import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@material-ui/core';

import useStyles from './css';
import { SuggestedServiceCardProps } from './props';
import { PlusIcon } from '../../../assets/icons';
import Tooltip from "@material-ui/core/Tooltip";
import { useAppSelector } from '../../../redux/hooks';


export default function SuggestedServiceCard(props: SuggestedServiceCardProps) {
    const classes = useStyles();

    const { t } = useTranslation(["general"]);

    const { service, handleAddService, showPrices, classCard } = props;

    const account = useAppSelector((state) => state.scheduler.account);

    function formatDecimalPrice(price: number): string | undefined {
      const decimalPart = price.toString().split('.')[1] || '';
      let decimalPrice: string;

      switch (decimalPart.length) {
        case 0:
          decimalPrice = `${price}.00`;
          break;
        case 1:
          decimalPrice = `${price}0`;
          break;
        default:
          decimalPrice = price.toString();
          break;
      }

      if (account.currencySymbol && account.currencyTemplate) {
        return formatPrice(decimalPrice, account.currencySymbol, account.currencyTemplate);
      }
    }

    function formatPrice(price: string, currencySymbol: string, valueTemplate: string): string {
      return valueTemplate.includes("{0}")
        ? valueTemplate.replace("{0}", price)
        : `${currencySymbol}${price}`;
    }

    return (
        <div className={`${classCard} ${classes.serviceCard}`}>
            <div className={classes.serviceImageContainer}>
                {service.photo &&
                    <img
                        className={classes.serviceImage}
                        src={service.photo}
                    />
                }
            </div>
            <div className={classes.serviceInfo}>
                <div className={classes.serviceNamePriceContainer}>
                    <Tooltip
                        title={`${service.categoryName} | ${service.name}`}
                        className={classes.tooltip}
                    >
                        <Typography className={classes.serviceName}>
                            {`${service.categoryName} | ${service.name}`}
                        </Typography>
                    </Tooltip>
                    <Typography className={classes.servicePrice}>
                        {showPrices &&
                          `${formatDecimalPrice(service.price!)} |`} {service.duration} {t("minutes")}
                    </Typography>
                </div>
                <div className={classes.serviceDescriptionContainer}>
                    <Tooltip
                        title={<span>{service.description}</span>}
                        className={classes.tooltip}
                    >
                        <Typography className={classes.serviceDescription}>
                            {service.description}
                        </Typography>
                    </Tooltip>
                </div>
                <div className={classes.addButtonContainer}>
                    <Button className={classes.addButton}
                        onClick={() => handleAddService(service)}
                        disabled={false}>
                        <div className={classes.addButtonItems}>
                            <PlusIcon />
                            {t("Add Service")}
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

import React from "react";

import useStyles from "./css";
import { EstablishmentCertificaitionsProps } from "./props";

export default function EstablishmentCertifications(props: EstablishmentCertificaitionsProps) {

  const classes = useStyles();

  const { certifications, handleCaruselOpen } = props;

  return (
    <div className={classes.establishmentCertsContainer}>
      {certifications.map((certURL: string, index: number) =>
        <div
          key={`cert_${index}`}
          className={classes.establishmentCert}
          style={{ backgroundImage: `url(${certURL})` }}
          onClick={() => handleCaruselOpen(certifications, index)}
        />
      )
      }
    </div>
  );
}
import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSwipeable } from "react-swipeable";

import useTerminology from "../../../../hooks/useTerminology";

import { useAppSelector } from "../../../../redux/hooks";
import Backdrop from "@material-ui/core/Backdrop";
import { Fade, IconButton, MobileStepper, Modal, Typography } from "@material-ui/core";

import useStyles from "./css";
import EstablishmentCertifications from "../EstablishmentCertifications";
import UserCertifications from "../UserCertifications";
import { ArrowIcon, CloseIcon } from "../../../../assets/icons";
import { AccountService } from "../../../../api/account-service";
import { AccountCertifications } from "../../../../models/account/account-certifications";
import { TerminologyType } from "../../../../models/enum/terminology-type";
import { TerminologyForm } from "../../../../models/enum/terminology-form";


export default function Certifications() {

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const account = useAppSelector((state) => state.scheduler.account);
  const [certifications, setCertifications] = useState<AccountCertifications>();

  const [isCarouselOpen, setIsCarouselOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [maxSteps, setMaxSteps] = useState<number>(0);
  const [pictures, setPictures] = useState<string[]>([]);

  const certsTerminology: string = useTerminology({ type: TerminologyType.Certification, form: TerminologyForm.Plural });
  const formattedCertsTerminology = (account.languageCode === "en")
    ? certsTerminology.toLowerCase()
    : certsTerminology;

  useEffect(() => {
    async function fetchUserCertifications() {
      if (account.id) {
        const certs = await AccountService.getCertificationsForAccount(account.id);
        setCertifications(certs);
      }
    }
    fetchUserCertifications();
  }, [account]);

  const handlerSwipe = useSwipeable({
    onSwipedLeft: () => nextImage(),
    onSwipedRight: () => previousImage(),
    onSwipedUp: () => setIsCarouselOpen(false),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  function keyDownImage(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === "ArrowLeft") {
      previousImage();
    }

    if (event.key === "ArrowRight") {
      nextImage();
    }

    if (event.key === "Escape") {
      setIsCarouselOpen(false);
    }
  }

  function previousImage() {
    setActiveStep((prevActiveStep) =>
      prevActiveStep <= 0 ? maxSteps - 1 : prevActiveStep - 1
    );
  }

  function nextImage() {
    setActiveStep((prevActiveStep) =>
      prevActiveStep >= maxSteps - 1 ? 0 : prevActiveStep + 1
    );
  }

  function handleCaruselOpen(certs: string[], index: number) {
    setPictures(certs);
    setActiveStep(index);
    setMaxSteps(certs.length);
    setIsCarouselOpen(true);
  }

  const renderCarouselModal = useMemo(() => {
    return (
      <Modal
        {...handlerSwipe}
        open={isCarouselOpen}
        onClose={() => setIsCarouselOpen(!isCarouselOpen)}
        className={classes.modal}
        BackdropComponent={Backdrop}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onKeyDown={keyDownImage}
      >
        <Fade in={isCarouselOpen}>
          <div className={classes.paper}>
            {isCarouselOpen &&
              <div
                className={classes.overlay}
                onClick={() => setIsCarouselOpen(false)}
              />
            }
            <div
              className={`${classes.imageModalContainer} ${pictures.length > 1
                ? classes.imageModalContainerWithPictures
                : classes.imageModalContainerWithPicture
                }`}
              style={{
                backgroundImage: `url("${pictures[activeStep]}")`,
              }}
            >
              <div className={classes.closeButtonContainer}>
                <IconButton className={classes.iconButton} onClick={() => setIsCarouselOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              {pictures.length > 1 &&
                <div className={classes.navigationButtonsContainer}>
                  <div className={classes.backButtonContainer}>
                    <IconButton className={classes.iconButton} onClick={previousImage}>
                      <ArrowIcon style={{ color: "#F6F6F6" }} />
                    </IconButton>
                  </div>
                  <div className={classes.nextButtonContainer}>
                    <IconButton className={classes.iconButton} onClick={nextImage}>
                      <ArrowIcon style={{ color: "#F6F6F6" }} className={classes.nextArrowIcon} />
                    </IconButton>
                  </div>
                </div>
              }
            </div>
            <MobileStepper
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={null}
              backButton={null}
              style={{ display: pictures.length > 1 ? "flex" : "none" }}
              classes={{ root: classes.stepper, dot: pictures.length > 1 ? classes.dot : classes.dotNone }}
            />
          </div>
        </Fade>
      </Modal>
    );
  }, [isCarouselOpen, pictures, activeStep, maxSteps, handlerSwipe, keyDownImage, previousImage, nextImage, classes]);

  return (
    <>
      {renderCarouselModal}
      {certifications &&
        <div className={classes.root}>
          {account.showEstablishmentCertifications && certifications.establishmentCertifications && certifications.establishmentCertifications.length > 0 &&
            <>
              <Typography className={classes.certsTitle}>
              {t("Establishment certifications", { terminology: formattedCertsTerminology })}
              </Typography>
              <EstablishmentCertifications certifications={certifications.establishmentCertifications} handleCaruselOpen={handleCaruselOpen} />
            </>
          }
          {account.showSpecialistCertifications && certifications.userCertifications && certifications.userCertifications.length > 0 &&
            <>
              <Typography className={classes.certsTitle}>
              {t("Specialist certifications", { terminology: formattedCertsTerminology })}
              </Typography>
              <UserCertifications usersWithCertifications={certifications.userCertifications} handleCaruselOpen={handleCaruselOpen} />
            </>
          }
        </div>
      }
    </>
  );
}
import React, { useState } from 'react';

import useStyles from './css';
import { SpecialistPhotoProps } from './props';


export default function SpecialistPhoto(props: SpecialistPhotoProps): JSX.Element | null {

  const classes = useStyles();

  const { initials, photoURL } = props;

  const [position, setPosition] = useState({ x: 0, y: 0 });

  function handleMouseMove(e: React.MouseEvent<HTMLDivElement>) {
    if (photoURL) {
      const img = new Image();

      img.src = photoURL;

      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;

      const aspectRatio = imgWidth / imgHeight;
      const size = 24;

      const [scaledWidth, scaledHeight] = imgWidth >= imgHeight
        ? [size, size / aspectRatio]
        : [size * aspectRatio, size];

      const widthScaleFactor = scaledWidth / size;
      const heightScaleFactor = scaledHeight / size;

      const containerRect = e.currentTarget.getBoundingClientRect();

      const cursorX = (e.clientX - containerRect.left) * widthScaleFactor;
      const cursorY = (e.clientY - containerRect.top) * heightScaleFactor;

      const margin = 5;
      const marginWidth = margin * widthScaleFactor;
      const marginHeight = margin * heightScaleFactor;

      const limitedCursorX = Math.max(marginWidth, Math.min(cursorX, size - marginWidth));
      const limitedCursorY = Math.max(marginHeight, Math.min(cursorY, size - marginHeight));

      const scaleFactor = 10;
      const scaleX = scaleFactor * widthScaleFactor;
      const scaleY = scaleFactor * heightScaleFactor;

      setPosition({ x: -limitedCursorX * scaleX, y: -limitedCursorY * scaleY });
    }
  };

  if (photoURL)
    return (
      <div
        className={classes.photo}
        style={{ backgroundImage: `url(${photoURL})` }}
        onMouseMove={handleMouseMove}
      >
        <div
          className={classes.largerPhoto}
          style={{
            backgroundImage: `url(${photoURL})`,
            backgroundPosition:
              `${position.x}px 
               ${position.y}px`,
          }}
        />
      </div>
    );

  if (initials && initials !== 'All')
    return (<div className={classes.emptyPhoto}> {initials.toUpperCase()} </div>);

  return null;
};

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
    },
    serviceImage: {
      width: "100%",
      height: 127,
      borderRadius: "5px 5px 0px 0px",
      background: theme.colors.grey2,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    boxContainer: {
      background: "#FFFFFF",
      borderRadius: "8px",
      cursor: "default",
      [theme.breakpoints.down("sm")]: {
        paddingRight: 46,
        paddingLeft: 5
      },
    },
    boxContent: {
      padding: "20px"
    },
    boxContentItem: {
      "&:not(:first-child)":{
        marginTop: 20,
      },
    },
    boxContentItemLabel: {
      alignItems: "center"
    },
    label: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
    },
    symbol: {
      ...theme.typography.txtBody2,
      fontWeight: 700,
      color: theme.colors.grey4,
      marginRight: 2
    },
    caption: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey5,
      marginLeft: 12,
      marginTop: 5,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: 235,
    },
    tooltipBlock: {
      backgroundColor: theme.colors.grey1,
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
    },
    divider: {
      width: "100%", 
      borderTop: "solid",
      borderWidth: 1,
      borderColor: "#C9CDD3",
    },
    cancelButton: {
      ...theme.typography.buttons,
      borderRadius: 51,
      width: "100%",
      margin: "0 10px",
      fontWeight: "bold",
      borderWidth: "2px",
      borderStyle: "solid",
      color: "#5C6477",
      borderColor: "#5C6477",
      "&.Mui-disabled": {
        color: "#5C6477",
        opacity: 0.25,
      },
    },
  })
);

export default useStyles;

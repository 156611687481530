import React, { useEffect, useState } from 'react';

import useStyles from "./css";

import { useAppSelector } from "../../../redux/hooks";

import { AppBar, Drawer, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";

import NavTabs from "../NavTabs";
import WhatsAppIcon from "../../../assets/icons/WhatsAppIcon";
import { useTranslation } from "react-i18next";
import CopyLinkIcon from "../../../assets/icons/CopyLinkIcon";
import { ClearBrandIcon } from "../../../assets/icons";
import PersonalizedAttentionModal from "../../common/PersonalizedAttentionModal";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SnackbarNotification, { SnackbarNotificationKind } from "../../common/notifications/SnackbarNotification";

export default function NavBar(props: any) {
  interface LogoContentProps {
    showSmallLogoImage: boolean;
  }
  
  const classes = useStyles();
  const account = useAppSelector((state) => state.scheduler.account);
  const theme = useTheme();
  const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
  const [isPersonalizedAttentionOpen, setIsPersonalizedAttentionOpen] = useState<boolean>(false);
  const [showLinkCopiedAlert, setShowLinkCopiedAlert] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation(["general"]);
  
  function handleOnShareClick() {
    navigator.clipboard.writeText("https://sitiodereservas.somosclear.com/" + account.businessNameUrl);
    setShowLinkCopiedAlert(true);
  }
  
  const TabsContent = () => {
    return (
      <div className={classes.tabsContent}>
        {
          !account.isEnterprise &&
          <NavTabs
            displayCertificationTab={account.showEstablishmentCertifications || account.showSpecialistCertifications || false}
          />
        }
        <div className={classes.buttonContainer}>
          {!account.isEnterprise && <span className={classes.personalizedAttentionButtonContainer}>
            <Button
              className={classes.personalizedAttentionButton}
              onClick={() => {
                setIsPersonalizedAttentionOpen(true);
              }}
            >
              <WhatsAppIcon color={"#36CE91"}/>
              {t("Personalized attention")}
            </Button>
          </span>}
          <Tooltip
            title={<span>{t("Copy link")} </span>}
            classes={{ tooltip: classes.tooltipBlock }}
          >
            <Button onClick={handleOnShareClick}>
              <CopyLinkIcon/>
              <div className={`${classes.shareButton} ${account.isEnterprise && !isMdMedia && classes.marginTop43}`}>
              {isMdMedia || account.isEnterprise ? "" : t("Copy link")}
            </div>
            </Button>
          </Tooltip>
          <SnackbarNotification
            isOpen={showLinkCopiedAlert}
            onClose={() => {
              setShowLinkCopiedAlert(false)
            }}
            message={t("Link copied.")}
            notificationKind={SnackbarNotificationKind.alert}
            anchorOriginHorizontal={"right"}
            anchorOriginVertical={"top"}
          />
        </div>
      </div>
    );
  }
  const LogoContent: React.FC<LogoContentProps> = ({ showSmallLogoImage }) => {
    return (
      <>
        <div className={classes.branding}>
          {account && account.logo ? (
            <div
              className={showSmallLogoImage ? classes.imageCircle : classes.imageCircleDrawer}
              style={{ backgroundImage: `url("${account.logo}")` }}
            ></div>
          ) : (
            <div
              className={showSmallLogoImage ? classes.brandingCircle : classes.brandingCircleDrawer}
              style={{ background: "#6462F3" }}
            >
              <ClearBrandIcon style={{ color: "#F6F6F6", width: 22, height: 30 }}/>
            </div>
          )}
        </div>
        <Typography className={classes.brandTitle}>
          {account?.name}
        </Typography>
      </>
    );
  }
  
  return (
    <AppBar position="fixed" className={classes.menuHorizontalTop}>
      <Toolbar variant="dense">
        <div className={classes.logoContent}>
          <LogoContent showSmallLogoImage={true}/>
        </div>
        {isMdMedia || account.isEnterprise
          ? <TabsContent/>
          : <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(true)}>
              <MenuIcon style={{ color: "#1D2027"}}/>
            </IconButton>
            <Drawer open={drawerOpen} anchor={"right"} onClose={() => setDrawerOpen(false)}
                    classes={{ paper: classes.paper }}>
              
              <div className={classes.logoDrawerContent}>
                <LogoContent showSmallLogoImage={false}/>
              </div>
              <TabsContent/>
            </Drawer>
          </>}
      </Toolbar>
      <PersonalizedAttentionModal
        open={isPersonalizedAttentionOpen}
        businessNameUrl={account?.businessNameUrl || ""}
        name={account?.name || ""}
        phone={account?.whatsAppChannelNumber || ""}
        onClose={() => {
          setIsPersonalizedAttentionOpen(false)
        }}/>
    </AppBar>
  );
}
import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import Draggable from 'react-draggable';

import { useAppSelector } from "../../../redux/hooks";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import { CartModalProps } from "./props";
import useStyles from "./css";
import CartIcon from "../../../assets/icons/CartIcon";
import ModalDragIcon from "../../../assets/icons/ModalDragIcon";
import ModalCloseIcon from "../../../assets/icons/ModalCloseIcon";


export default function CartModal(props: CartModalProps) {

  const { t } = useTranslation(["general"]);
  const classes = useStyles();

  const { onClose, services, sheduleAppt } = props;

  const totalPrice = services.reduce((total, x) => total + (x.service?.price || 0), 0);

  const account = useAppSelector((state) => state.scheduler.account);

  let timer: any;

  useEffect(() => {
    timer = window.setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  function handleDragStart() {
    if (timer) {
      clearTimeout(timer);
    }
  };

  return (
    <>
      <div className={classes.overlay} onClick={onClose}/>
      <Draggable
        handle={`.${classes.modalDragContainer}`}
        bounds={{ top: 0, right: 20, bottom: window.innerHeight - 300, left: 380 - window.innerWidth }}
        onStart={handleDragStart}
      >
        <Paper className={classes.modalContainer}>
          <div className={classes.modalLeftPanelContainer}>
            <div className={classes.modalLeftPanel} />
            <div className={classes.modalContentWrapper}>
              <div className={classes.modalClosePanel}>
                <div className={classes.modalDragContainer}>
                  <ModalDragIcon />
                </div>
                <ModalCloseIcon className={classes.closeIcon} onClick={onClose} />
              </div>
              <div className={classes.modalContentContainer}>
                <div className={classes.modalHeader}>
                  <Typography className={classes.headerTitle}>
                    {`${services.length} ${t("service added")}`}
                  </Typography>
                </div>
                <div className={classes.modalContent}>
                  {services.map((serviceUser, index) => (
                    <div key={index} className={classes.serviceInfo}>
                      <CartIcon className={classes.cartIcon} />
                      <div className={classes.serviceInfoText}>
                        <Typography className={classes.serviceName}>{serviceUser.service?.name}</Typography>
                        <Typography className={classes.serviceDuration}>{`${serviceUser.service?.duration} ${t("minutes")}`}</Typography>
                      </div>
                      {account.showPrices &&
                        <Typography className={classes.servicePrice}>{`$${serviceUser.service?.price}`}</Typography>
                      }
                    </div>
                  ))}
                </div>
                {account.showPrices &&
                  <div className={classes.totalContent}>
                    <Typography className={classes.totalPrice}>
                      {`${t("Total")}: $${totalPrice}`}
                    </Typography>
                  </div>
                }
                <div className={classes.modalActions}>
                  <Button className={classes.scheduleButton} onClick={sheduleAppt}>
                    {t("Schedule appt")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Draggable>
    </>
  );
};

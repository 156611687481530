import { AccountWorkDayModel } from "./account-work-day.model";
import { Terminology } from "./terminology";

export class AccountInfoModel {
  id: string | null;
  name: string | null;
  phoneNumber: string | null;
  phoneCodeIso3: string | null;
  phoneDigits: number | null;
  phoneCode: number | null;
  phoneTemplate: string | null;
  address: string | null;
  addressReferences: string | null;
  pictures: string[] | null;
  workDays: AccountWorkDayModel[] | null;
  showCancellationPolicies: boolean;
  cancellationPolicies: string | null;
  isPrivacyNoticeEnabled: boolean | null;
  privacyNotice: string | null;
  logo: string | null;
  currencySymbol: string | null;
  currencyTemplate: string | null;
  isPaymentCash: string | null;
  isPaymentCard: string | null;
  isPaymentBankTransfer: string | null;
  isPaymentOxxo: string | null;
  isPaymentMercadoPago: string | null;
  showPrices: boolean | null;
  showSuggestedServices: boolean | null;
  terminologies: Terminology[] | null;
  showEstablishmentCertifications: boolean | null;
  showSpecialistCertifications: boolean | null;
  allowCustomerToCancelFromSite: boolean | null;
  allowCustomerToModifyFromSite: boolean | null;
  allowCustomerScheduleSimultaneousServices: boolean | null;
  isEnterprise: boolean | null;
  enterpriseBusinessNameUrl: boolean | null;
  allowCustomerToScheduleTodayFromSite: boolean | null;
  businessNameUrl: string | null;
  languageCode: string | null;
  whatsAppChannelNumber: string | null;
  requireSelectSpecialistFromSite: boolean | null;
  showAnySpecialistFromSite: boolean | null;
  isClinicMode: boolean | null;
  isClinicalHistoryEnabled: boolean | null;
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.phoneNumber = x.phoneNumber;
      this.phoneCodeIso3 = x.phoneCodeIso3;
      this.phoneDigits = x.phoneDigits;
      this.phoneCode = x.phoneCode;
      this.phoneTemplate = x.phoneTemplate;
      this.address = x.address;
      this.addressReferences = x.addressReferences;
      this.pictures = x.pictures;
      this.workDays = x.workDays;
      this.showCancellationPolicies = x.showCancellationPolicies;
      this.cancellationPolicies = x.cancellationPolicies;
      this.isPrivacyNoticeEnabled = x.isPrivacyNoticeEnabled;
      this.privacyNotice = x.privacyNotice;
      this.logo = x.logo;
      this.currencySymbol = x.currencySymbol;
      this.currencyTemplate = x.currencyTemplate;
      this.isPaymentCash = x.isPaymentCash;
      this.isPaymentCard = x.isPaymentCard;
      this.isPaymentOxxo = x.isPaymentOxxo;
      this.isPaymentMercadoPago = x.isPaymentMercadoPago;
      this.isPaymentBankTransfer = x.isPaymentBankTransfer;
      this.showPrices = x.showPrices;
      this.showSuggestedServices = x.showSuggestedServices;
      this.terminologies = x.terminologies;
      this.showEstablishmentCertifications = x.showEstablishmentCertifications;
      this.showSpecialistCertifications = x.showSpecialistCertifications;
      this.allowCustomerToCancelFromSite = x.allowCustomerToCancelFromSite;
      this.allowCustomerToModifyFromSite = x.allowCustomerToModifyFromSite;
      this.businessNameUrl = x.businessNameUrl;
      this.languageCode = x.languageCode;
      this.whatsAppChannelNumber = x.whatsAppChannelNumber;
      this.requireSelectSpecialistFromSite = x.requireSelectSpecialistFromSite;
      this.showAnySpecialistFromSite = x.showAnySpecialistFromSite;
      this.allowCustomerScheduleSimultaneousServices = x.allowCustomerScheduleSimultaneousServices;
      this.isEnterprise = x.isEnterprise;
      this.enterpriseBusinessNameUrl = x.enterpriseBusinessNameUrl;
      this.allowCustomerToScheduleTodayFromSite = x.allowCustomerToScheduleTodayFromSite;
      this.isClinicMode = x.isClinicMode;
      this.isClinicalHistoryEnabled = x.isClinicalHistoryEnabled;
    } else {
      this.id = null;
      this.name = null;
      this.phoneNumber = null;
      this.phoneCodeIso3 = null;
      this.phoneDigits = null;
      this.phoneCode = null;
      this.phoneTemplate = null;
      this.address = null;
      this.addressReferences = null;
      this.pictures = null;
      this.workDays = null;
      this.isPaymentOxxo = null;
      this.isPaymentMercadoPago = null;
      this.showCancellationPolicies = false;
      this.cancellationPolicies = null;
      this.isPrivacyNoticeEnabled = false;
      this.privacyNotice = null;
      this.logo = null;
      this.currencySymbol = null;
      this.currencyTemplate = null;
      this.isPaymentCash = null;
      this.isPaymentCard = null;
      this.isPaymentBankTransfer = null;
      this.showPrices = false;
      this.showSuggestedServices = false;
      this.terminologies = null;
      this.showEstablishmentCertifications = false;
      this.showSpecialistCertifications = false;
      this.allowCustomerToCancelFromSite = true;
      this.allowCustomerToModifyFromSite = true;
      this.businessNameUrl = null;
      this.languageCode = null;
      this.whatsAppChannelNumber = null;
      this.requireSelectSpecialistFromSite = null;
      this.showAnySpecialistFromSite = null;
      this.allowCustomerScheduleSimultaneousServices = null;
      this.allowCustomerToScheduleTodayFromSite = null;
      this.isEnterprise = null;
      this.enterpriseBusinessNameUrl = null;
      this.isClinicMode = null;
      this.isClinicalHistoryEnabled = null;
    }
  }
}


import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 25,
      margin: 18,
      paddingTop: 28,
      paddingLeft: 22,
      paddingRight: 30,
      borderRadius: 6,
      background: theme.colors.white,
      boxShadow: '2px 2px 12px 5px rgba(235, 235, 235, 0.46)',
    },
    certsTitle: {
      ...theme.typography.header4,
      color: theme.colors.grey6,
      fontWeight: 700
    },
    overlay: {
      [theme.breakpoints.down("sm")]: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(50, 57, 71, 0.76)',
        zIndex: 999,
      },
    },
    stepper: {
      justifyContent: "center",
      height: 48,
      borderRadius: "0px 0px 20px 20px",
      position: "relative",
      zIndex: 1000
    },
    dot: {
      marginLeft: 22,
      "&:first-child": {
        marginLeft: 0,
      },
    },
    dotNone: {
      display: "none !important"
    },
    imageModalContainer: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      minHeight: "65vh",

      [theme.breakpoints.down("sm")]: {
        minHeight: "40vh",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

    },
    paper: {
      flexBasis: "50%",
      "&:focus:not(:focus-visible)": {
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },

      [theme.breakpoints.down("sm")]: {
        flexBasis: "98%",
      },
    },
    closeButtonContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    iconButton: {
      width: 32,
      height: 32,
      background: "rgba(29, 32, 39, 0.15)",
      borderRadius: 30,
      marginRight: 10,
      marginLeft: 10,
      marginTop: 10,
      "&:hover": {
        background: "rgba(29, 32, 39, 0.15)",
      }

    },
    navigationButtonsContainer: {
      display: "flex",
      width: "100%",
      marginTop: "23.5%",
      justifyContent: "space-between",
    },
    backButtonContainer: {
      display: "flex",
      justifyContent: "flex-start",
      width: "50%",
    },
    nextButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "50%",
    },
    nextArrowIcon: {
      transform: "rotate(180deg)",
    },
    imageModalContainerWithPictures: {
      borderRadius: "20px 20px 0px 0px",
      position: "relative",
      zIndex: 1000,
    },
    imageModalContainerWithPicture: {
      borderRadius: "20px",
      position: "relative",
      zIndex: 1000,
    },
  })
);

export default useStyles;

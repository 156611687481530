import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuHorizontalTop: {
    paddingTop: 3,
    paddingBottom: 3,
    height: 64,
    backgroundColor: "#fff!important",
    color: "#919CA5",
    fontWeight: "bold",
    borderBottom: "1px solid #C9CDD3",
    // Centering AppBar
    position: "fixed",
    left: 0,
    right: 0,
    margin: "auto",
    "& .MuiToolbar-root": {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between",
      },
    },
  },
  paper: {
    color: "#fff",
    background: "#FAFAFA",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    width: "250px",
  },
  shareButton: {
    ...theme.typography.buttons,
    fontWeight: 700,
    color: "#1D2027",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 8
    },
  },
  personalizedAttentionButton: {
    width: 189,
    borderRadius: 5,
    border: "0.5px solid",
    borderColor: theme.colors.clear2,
    background: '#FAFAFA',
    ...theme.typography.buttons,
    fontWeight: "bold",
    color: theme.colors.clear2,
    height: 32,
    "&.Mui-disabled": {
      color: theme.colors.grey1,
      opacity: 0.3,
    },
    "& .MuiButton-label": {
      gap: "12px"
    },
  },
  branding: {
    display: "flex",
    justifyContent: "center",
  },
  marginTop43: {
    marginTop: 43
  },
  personalizedAttentionButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      "&::after": {
        content: "''",
        height: "100%",
        borderBottom: "1px solid #ACB7C0",
        margin: "16px 0 8px 0",
      },
    },
  },
  brandingCircle: {
    height: 30,
    width: 30,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  brandingCircleDrawer: {
    height: 39,
    width: 39,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imageCircle: {
    height: 30,
    width: 30,
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  imageCircleDrawer: {
    height: 46,
    width: 46,
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  brandTitle: {
    ...theme.typography.header4,
    fontWeight: 700,
    color: theme.colors.grey7,
    display: "flex",
    justifyContent: "center",
  },
  logoContent: {
    display: "flex",
    alignItems: "center",
    gap: 16
  },
  logoDrawerContent: {
    display: "flex",
    flexDirection: "column",
    marginTop: 23,
    marginBottom: 33,
    gap: 8
  },
  buttonContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      gap: 8
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginLeft: "unset",
      paddingBottom: 10
    },
  },
  tabsContent: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: "unset",
      height: "100%",
      justifyContent: "space-between"
    },
  },
  tooltipBlock: {
    backgroundColor: theme.colors.grey1,
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
})
);

export default useStyles;
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";


const CartIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 22, height: 20, ...style }}
      viewBox={viewBox || "0 0 22 20"}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M0.264587 0.853058C0.345821 0.446888 0.74094 0.183475 1.14711 0.264709L2.19647 0.474581C3.38172 0.711631 4.27323 1.69482 4.3935 2.89754L4.47876 3.75014H18.0542C20.0385 3.75014 21.4944 5.61488 21.0132 7.53988L19.8904 12.0309C19.4174 13.9229 17.7175 15.2501 15.7673 15.2501H6.77437C5.38232 15.2501 4.20982 14.2099 4.04396 12.8278L3.05448 4.58215L2.90095 3.0468C2.84628 2.50011 2.44105 2.0532 1.9023 1.94545L0.852935 1.73558C0.446766 1.65435 0.183353 1.25923 0.264587 0.853058ZM7.99988 10.75C7.58566 10.75 7.24988 11.0858 7.24988 11.5C7.24988 11.9142 7.58566 12.25 7.99988 12.25H11.9999C12.4141 12.25 12.7499 11.9142 12.7499 11.5C12.7499 11.0858 12.4141 10.75 11.9999 10.75H7.99988Z" fill="#6462F3" />
      <circle cx="7.5" cy="18" r="1.5" fill="#6462F3" />
      <circle cx="16.5" cy="18" r="1.5" fill="#6462F3" />
    </SvgIcon>
  );
};

export default CartIcon;

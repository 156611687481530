import { ApiClient } from "./api-client";
import { AccountCertifications } from "../models/account/account-certifications";


export class AccountService {
  private static baseUrl: string = "/CustomerSite";

  static async getCertificationsForAccount(accountId: string | null): Promise<AccountCertifications> {
    return await ApiClient.get(
      `${this.baseUrl}/GetCertificationsForAccount?accountId=${accountId}`)
      .then(data => data);
  }
}

import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import useTerminology from "../../../../hooks/useTerminology";

import { Button, Tooltip, Typography } from "@material-ui/core";

import useStyles from "./css";
import { UserCardProps } from "./props";
import { UserModel } from "../../../../models/user";
import { TerminologyType } from "../../../../models/enum/terminology-type";
import { TerminologyForm } from "../../../../models/enum/terminology-form";


export default function UserCard(props: UserCardProps) {

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const certsTerminology: string = useTerminology({ type: TerminologyType.Certification, form: TerminologyForm.Plural });

  const { userWithCertifications, handleCaruselOpen } = props;

  const [showAllServices, setShowAllServices] = useState(false);

  function getInitials(user: UserModel) {
    if (user.firstName && user.lastName) {
      const firstInitial = user.firstName[0].toUpperCase();
      const lastInitial = user.lastName[0].toUpperCase();
      return `${firstInitial}${lastInitial}`;
    }
  };

  const UserPhoto = (user: UserModel) => {
    if (!user) return null;

    if (!user.photoURL) return (
      <div className={classes.photoInitials}>
        {getInitials(user)}
      </div>
    );

    return (
      <div
        className={classes.userPhoto}
        style={{ backgroundImage: `url(${user.photoURL})` }}
      />
    );
  }

  function renderServicesList(services: string[]) {
    if (!services) return;

    const sortedServices = services.sort((a, b) => (a).localeCompare(b));

    const displayedServices = showAllServices
      ? sortedServices
      : sortedServices.slice(0, 3);

    return (
      <div className={classes.servicesList}>
        {displayedServices.map((serviceName, index) => (
          serviceName &&
          <Tooltip title={serviceName!} key={index}>
            <li key={index} className={classes.serviceName}>
              {serviceName}
            </li>
          </Tooltip>
        ))}
        {sortedServices.length > 3 && (
          <li
            className={classes.moreServicesButton}
            onClick={() => setShowAllServices(prev => !prev)}
          >
            {showAllServices
              ? t('See less')
              : t('See more')
            }
          </li>
        )}
      </div>
    );
  }

  if (!userWithCertifications || !userWithCertifications.user) {
    return null;
  }

  return (
    <div className={classes.userCard}>
      <div className={classes.userPhotoContainer}>
        <UserPhoto {...userWithCertifications.user} />
      </div>
      <div className={classes.userInfoContainer}>
        <Typography className={classes.userFullName}>
          {`${userWithCertifications.user.firstName} ${userWithCertifications.user.lastName}`}
        </Typography>
        <Typography className={classes.userJobPosition}>
          {t(userWithCertifications.user.jobPosition!)}
        </Typography>
        {
          renderServicesList(userWithCertifications.serviceNames)
        }
        <div className={classes.viewButtonContainer}>
          <Button
            className={classes.viewButton}
            onClick={() => handleCaruselOpen(userWithCertifications.certifications, 0)}
          >
            {t("View certifications", { terminology: certsTerminology.toLowerCase() })}
          </Button>
        </div>
      </div>
    </div>
  );
}
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";


const ModalDragIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 8, height: 12, ...style }}
      viewBox={viewBox || "0 0 8 12"}
    >
      <path d="M1.91669 3.12807C2.60704 3.12807 3.16669 2.57703 3.16669 1.89729C3.16669 1.21754 2.60704 0.666504 1.91669 0.666504C1.22633 0.666504 0.666687 1.21754 0.666687 1.89729C0.666687 2.57703 1.22633 3.12807 1.91669 3.12807Z" fill="#919CA5" />
      <path d="M1.91669 7.23061C2.60704 7.23061 3.16669 6.67957 3.16669 5.99982C3.16669 5.32008 2.60704 4.76904 1.91669 4.76904C1.22633 4.76904 0.666687 5.32008 0.666687 5.99982C0.666687 6.67957 1.22633 7.23061 1.91669 7.23061Z" fill="#919CA5" />
      <path d="M1.91669 11.3336C2.60704 11.3336 3.16669 10.7826 3.16669 10.1029C3.16669 9.42311 2.60704 8.87207 1.91669 8.87207C1.22633 8.87207 0.666687 9.42311 0.666687 10.1029C0.666687 10.7826 1.22633 11.3336 1.91669 11.3336Z" fill="#919CA5" />
      <path d="M6.08337 3.12807C6.77373 3.12807 7.33337 2.57703 7.33337 1.89729C7.33337 1.21754 6.77373 0.666504 6.08337 0.666504C5.39302 0.666504 4.83337 1.21754 4.83337 1.89729C4.83337 2.57703 5.39302 3.12807 6.08337 3.12807Z" fill="#919CA5" />
      <path d="M6.08337 7.23061C6.77373 7.23061 7.33337 6.67957 7.33337 5.99982C7.33337 5.32008 6.77373 4.76904 6.08337 4.76904C5.39302 4.76904 4.83337 5.32008 4.83337 5.99982C4.83337 6.67957 5.39302 7.23061 6.08337 7.23061Z" fill="#919CA5" />
      <path d="M6.08337 11.3336C6.77373 11.3336 7.33337 10.7826 7.33337 10.1029C7.33337 9.42311 6.77373 8.87207 6.08337 8.87207C5.39302 8.87207 4.83337 9.42311 4.83337 10.1029C4.83337 10.7826 5.39302 11.3336 6.08337 11.3336Z" fill="#919CA5" />
    </SvgIcon>
  );
};

export default ModalDragIcon;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CalendarIcon = (props: any) => {
  const { style, viewBox, height, width, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: width || 12,  height: height || 14, ...style }}
      viewBox={viewBox || "0 0 12 14"}
    >
          <path
              d="M3.44507 12.3333C3.44507 12.8958 3.89723 13.3333 4.47859 13.3333H12.0577C12.6176 13.3333 13.0913 12.8958 13.0913 12.3333V6.66663H3.44507V12.3333ZM10.3352 8.24996C10.3352 8.12496 10.4429 7.99996 10.5936 7.99996H11.4549C11.5841 7.99996 11.7132 8.12496 11.7132 8.24996V9.08329C11.7132 9.22913 11.5841 9.33329 11.4549 9.33329H10.5936C10.4429 9.33329 10.3352 9.22913 10.3352 9.08329V8.24996ZM10.3352 10.9166C10.3352 10.7916 10.4429 10.6666 10.5936 10.6666H11.4549C11.5841 10.6666 11.7132 10.7916 11.7132 10.9166V11.75C11.7132 11.8958 11.5841 12 11.4549 12H10.5936C10.4429 12 10.3352 11.8958 10.3352 11.75V10.9166ZM7.57915 8.24996C7.57915 8.12496 7.68681 7.99996 7.83753 7.99996H8.6988C8.82799 7.99996 8.95718 8.12496 8.95718 8.24996V9.08329C8.95718 9.22913 8.82799 9.33329 8.6988 9.33329H7.83753C7.68681 9.33329 7.57915 9.22913 7.57915 9.08329V8.24996ZM7.57915 10.9166C7.57915 10.7916 7.68681 10.6666 7.83753 10.6666H8.6988C8.82799 10.6666 8.95718 10.7916 8.95718 10.9166V11.75C8.95718 11.8958 8.82799 12 8.6988 12H7.83753C7.68681 12 7.57915 11.8958 7.57915 11.75V10.9166ZM4.8231 8.24996C4.8231 8.12496 4.93076 7.99996 5.08148 7.99996H5.94275C6.07194 7.99996 6.20113 8.12496 6.20113 8.24996V9.08329C6.20113 9.22913 6.07194 9.33329 5.94275 9.33329H5.08148C4.93076 9.33329 4.8231 9.22913 4.8231 9.08329V8.24996ZM4.8231 10.9166C4.8231 10.7916 4.93076 10.6666 5.08148 10.6666H5.94275C6.07194 10.6666 6.20113 10.7916 6.20113 10.9166V11.75C6.20113 11.8958 6.07194 12 5.94275 12H5.08148C4.93076 12 4.8231 11.8958 4.8231 11.75V10.9166ZM12.0577 3.99996H11.0242V2.99996C11.0242 2.83329 10.852 2.66663 10.6797 2.66663H9.9907C9.79692 2.66663 9.6462 2.83329 9.6462 2.99996V3.99996H6.89014V2.99996C6.89014 2.83329 6.71789 2.66663 6.54563 2.66663H5.85662C5.66283 2.66663 5.51211 2.83329 5.51211 2.99996V3.99996H4.47859C3.89723 3.99996 3.44507 4.45829 3.44507 4.99996V5.99996H13.0913V4.99996C13.0913 4.45829 12.6176 3.99996 12.0577 3.99996Z"
              fill="#5C6477"
          />
    </SvgIcon>
  );
};

export default CalendarIcon;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";


const ModalCloseIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 16, height: 16, ...style }}
      viewBox={viewBox || "0 0 16 16"}
    >
      <path d="M11.7712 4.22876C11.5998 4.05728 11.3672 3.96094 11.1247 3.96094C10.8822 3.96094 10.6496 4.05728 10.4781 4.22876L8 6.70686L5.52189 4.22876C5.35041 4.05728 5.11784 3.96094 4.87533 3.96094C4.63282 3.96094 4.40024 4.05728 4.22876 4.22876C4.05728 4.40024 3.96095 4.63281 3.96095 4.87532C3.96095 5.11783 4.05728 5.35041 4.22876 5.52189L6.70687 7.99999L4.22876 10.4781C4.05728 10.6496 3.96095 10.8822 3.96095 11.1247C3.96095 11.3672 4.05728 11.5997 4.22876 11.7712C4.40024 11.9427 4.63282 12.039 4.87533 12.039C5.11784 12.039 5.35041 11.9427 5.52189 11.7712L7.99765 9.29547L10.4758 11.7736C10.6472 11.9451 10.8798 12.0414 11.1223 12.0414C11.3648 12.0414 11.5974 11.9451 11.7689 11.7736C11.9404 11.6021 12.0367 11.3695 12.0367 11.127C12.0367 10.8845 11.9404 10.6519 11.7689 10.4805L9.29313 8.0047L11.7736 5.52424C11.8587 5.43913 11.9262 5.33806 11.9721 5.22683C12.0181 5.11559 12.0416 4.99637 12.0414 4.87601C12.0412 4.75565 12.0172 4.63652 11.9708 4.52545C11.9245 4.41438 11.8566 4.31356 11.7712 4.22876Z" fill="#919CA5" />
    </SvgIcon>
  );
};

export default ModalCloseIcon;

import React from 'react';

import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../redux/hooks';

import { Stepper, Step, StepConnector, StepLabel, withStyles } from "@material-ui/core";

import { AppointmentScheduleStepperProps } from "./props";
import useStyles, { useStepIconStyles } from './css';
import MuiCheckIcon from '../../../assets/icons/MuiCheckIcon';
import TimeToEditExpiredMessage from "../../common/TimeToEditExpiredMessage";


export default function AppointmentScheduleStepper(props: AppointmentScheduleStepperProps) {
  
  const { activeStep } = props;
  
  const { t } = useTranslation("general");
  
  const classes = useStyles()();
  
  const StyledStepperConnector = withStyles((theme) => ({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    line: {
      borderColor: theme.colors.grey2,
      borderTopWidth: 1,
      borderRadius: 1
    },
  }))(StepConnector);

  const account = useAppSelector((state) => state.scheduler.account);
  const isClinicMode = account.isClinicMode;

  function StepIcon(props: any) {
    const classes = useStepIconStyles();
    const { active, completed } = props;
    
    return (
      <div
        className={classes.root}
      >
        {completed ? <MuiCheckIcon className={classes.completed} /> : active ? <div className={classes.activeCircle} /> : <div className={classes.circle} />}
      </div>
    );
  }
  
  return (
    <div className={classes.root}>
      <TimeToEditExpiredMessage/>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StyledStepperConnector />}
        className={classes.stepper}
      >
        <Step key={0}>
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{
              alternativeLabel: classes.labelAlternativeLabel,
              completed: classes.labelCompleted
            }}
          >
            <div>
              {t("STEP 1")}
            </div>
            <div>
              {t("Services")}
            </div>
          </StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{
              alternativeLabel: classes.labelAlternativeLabel,
              completed: classes.labelCompleted
            }}
          >
            <div>
              {t("STEP 2")}
            </div>
            <div>
              {isClinicMode
                ? t("Patient details")
                : t("Customer details")
              }
            </div>
          </StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel
            StepIconComponent={StepIcon}
            classes={{
              alternativeLabel: classes.labelAlternativeLabel,
              completed: classes.labelCompleted
            }}
          >
            <div>
              {t("STEP 3")}
            </div>
            <div>
              {t("Confirmation")}
            </div>
          </StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}
import React from "react";

import { useTranslation } from "react-i18next";
import { conformToMask } from "react-text-mask";
import { format } from 'date-fns';
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import { useAppSelector } from "../../../redux/hooks";

import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Link, Tooltip } from "@material-ui/core";

import { ClockIcon, UserIcon } from "./../../../assets/icons";
import { ServiceUserModel } from "../../../models/service/service-user.model";
import useStyles from "./css";
import WhatsAppIcon from "../../../assets/icons/WhatsAppIcon";
import { GetMobileMaskByTemp } from "../../../constants/masks";
import CalendarInverseFillIcon from "../../../assets/icons/CalendarInverseFillIcon";
import SpecialistPhoto from "../SpecialistPhoto";


export default function ScheduleAppointmentConfirmationStep() {
    const { t } = useTranslation(["general"]);
    const theme = useTheme();
    const isMdMedia = useMediaQuery(theme.breakpoints.up("md"));
    const classes = useStyles();

    const serviceUsers = useAppSelector((state) => state.scheduler.serviceUser);
    const scheduleTime = useAppSelector((state) => state.scheduler.scheduleTime);
    const date = useAppSelector((state) => state.scheduler.currentDate);
    const customer = useAppSelector((state) => state.scheduler.scheduleAppointment);

    const localeApp = useAppSelector(state => state.scheduler.locale);
    const account = useAppSelector((state) => state.scheduler.account);

    const isServicesPerformedSimultaneously = useAppSelector((state) => state.scheduler.isServicesPerformedSimultaneously);

    const appointmentTime = getAppointmentTime();

    function getSpecialistName(serviceUser: ServiceUserModel) {
        if (serviceUser.userId === 'All') {
            return t("Any specialist");
        }
        let specialist = serviceUser.users?.find(user => user.id == serviceUser.userId);
        return `${specialist?.firstName} ${specialist?.lastName}`;
    }

    function specialistPhoto(serviceUser: ServiceUserModel) {
      if (serviceUser.userId === 'All')
        return (<UserIcon style={{ width: 30, height: 12 }} />);

      const user = serviceUser.users?.find(user => user.id == serviceUser.userId);

      if (!user) return;

      const initials = `${user.firstName![0]}${user.lastName![0]}` || null;
      const photoURL = user.photoURL;

      return (
        <div style={{ marginTop: -5 }}>
          <SpecialistPhoto
            initials={initials}
            photoURL={photoURL}
          />
        </div> 
      );
    }

    function getAppointmentDate(): string {
        let dateFormat = localeApp == 'es'
            ? "dd 'de' MMMM yyyy"
            : "MMMM dd, yyyy";
        return format(date, dateFormat, { locale: getLocale() });
    }

    function getAppointmentTime(): string[] {
        let startAppointmentTime = scheduleTime?.scheduleTime?.split(" - ")[0];

        let appointmentTimes: string[] = [];

        if (!startAppointmentTime) return appointmentTimes;

        serviceUsers.forEach((serviceUser, index) => {
            let duration = serviceUser.service?.duration;
            if (isServicesPerformedSimultaneously) {
                appointmentTimes.push(scheduleTime?.scheduleTime!);
                return;
            }

            if (duration) {
                let durationSum = serviceUsers.slice(0, index)
                    .map((user: ServiceUserModel) => user.service?.duration || 0)
                    .reduce((total: number, d: number) => total + (d || 0), 0);

                let startTime = addMinutes(startAppointmentTime!, durationSum);
                let endTime = addMinutes(startTime, duration);

                appointmentTimes.push(`${startTime} - ${endTime}`);
            }
        });

        return appointmentTimes;
    }

    function addMinutes(time: string, minutes: number): string {
        let [hours, mins] = time.split(":").map(Number);
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(mins + minutes);
        return `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
    }

    function getLocale(): any {
        switch (localeApp) {
            case "es":
                return esLocale;

            default:
                return enLocale;
        }
    }

    function maskedMobileNumber(mobileCode: number, mobileNumber: string, mobileTemplate: string) {
        const mobile = conformToMask(mobileNumber, GetMobileMaskByTemp(mobileTemplate)).conformedValue;
        return `${mobile}`
    }

  function formatPrice(price: number, currencySymbol: string, valueTemplate: string) {
    return valueTemplate.includes("{0}")
      ? valueTemplate.replace("{0}", price.toString())
      : `${currencySymbol}${price}`;
  }

    return (<div className={classes.stepRoot}>
        <Typography className={classes.title}>
            {t("This is the last step!")}
        </Typography>
        <div className={classes.stepContainer}>
            <div className={classes.customerInfoContainer}>
                <div className={classes.customerInfo}>
                    <Typography className={classes.infoTitle}>
                        {`${customer.firstName} ${customer.lastName}`}
                    </Typography>
                    <div className={classes.appointmentInfoDateContainer}>
                        <WhatsAppIcon style={{ width: 16, height: 16 }} color={'rgb(145, 156, 165)'} />
                        <Typography className={classes.infoText}>
                            {maskedMobileNumber(customer.mobileCode!, customer.mobile, customer.mobileTemplate!)}
                        </Typography>
                    </div>
                    <hr className={classes.line} />
                    <div className={classes.customerCommentContainer}>
                        <Typography className={classes.commentText}>
                            {customer.notes}
                        </Typography>
                    </div>
                </div>
            </div>

            <div className={classes.appointmentInfoContainer}>
                <div className={classes.appointmentInfo}>
                    <Typography className={classes.infoTitle}>
                        {t("Appointment information")}
                    </Typography>
                    <div className={classes.appointmentInfoDateContainer}>
                        <CalendarInverseFillIcon style={{ width: 16, height: 16 }} />
                        <Typography className={classes.infoText}>
                            {getAppointmentDate()}
                        </Typography>
                    </div>
                    <Typography className={classes.infoTitle}>
                        {t("Services")}
                    </Typography>
                    {serviceUsers && serviceUsers.map((serviceUser: ServiceUserModel, index: number) => (
                        <div key={index} className={classes.serviceInfo}>
                            <div className={isMdMedia ? classes.serviceInfoRow : ""}>
                                <div className={classes.serviceInfoColumn}>
                                    <Tooltip title={serviceUser.service?.name || ""}>
                                        <Typography className={classes.infoServiceName}>
                                            {serviceUser.service?.name}
                                        </Typography>
                                    </Tooltip>
                                </div>
                                <div className={isMdMedia ? classes.serviceInfoColumn : classes.serviceInfoTimeWithSpecialistContainer}>
                                    <div className={classes.timeContainer}>
                                        <ClockIcon style={{ width: 12, height: 12 }} />
                                        <Typography className={classes.infoText}>
                                            {appointmentTime[index]}
                                        </Typography>
                                    </div>
                                    <div className={classes.specialistContainer}>
                                        {specialistPhoto(serviceUser)}                                       
                                        <Tooltip title={getSpecialistName(serviceUser)}>
                                            <Typography className={classes.infoText}>
                                                {getSpecialistName(serviceUser)}
                                            </Typography>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <div>
                          <Typography className={classes.infoPriceText}>
                                    {account.showPrices && serviceUser.service && serviceUser.service.price && account.currencySymbol && account.currencyTemplate
                                      && `${formatPrice(serviceUser.service.price, account.currencySymbol, account.currencyTemplate)} | `}
                                    {serviceUser.service?.duration} {t("minutes")}
                                </Typography>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>

        <div className={classes.cancellationContainer}>
            {account?.showCancellationPolicies && account?.cancellationPolicies &&
                <div className={classes.cancellationTextContainer}>
                    <Typography className={classes.cancellationTitle}>
                        {t("Cancellation policies")}
                    </Typography>
                    <Typography className={classes.cancellationText}>
                        {t("To learn about our cancellation policies, ")}
                        <Link href={`/${account.businessNameUrl}/about`} target="_blank" className={classes.cancellationLink}>
                            {t("click here.")}
                        </Link>
                    </Typography>
                </div>
            }
            {account?.isPrivacyNoticeEnabled && account?.privacyNotice &&
                <div className={classes.cancellationTextContainer}>
                    <Typography className={classes.cancellationTitle}>
                        {t("Privacy notice")}
                    </Typography>
                    <Typography className={classes.cancellationText}>
                        {t("To learn about our privacy notice, ")}
                        <Link href={`/${account.businessNameUrl}/privacy-notice`} target="_blank" className={classes.cancellationLink}>
                            {t("click here.")}
                        </Link>
                    </Typography>
                </div>
            }
        </div>
    </div>);
};

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    establishmentCertsContainer: {
      maxWidth: '100%',
      minWidth: 270,
      maxHeight: 270,
      display: 'flex',
      flexWrap: 'wrap',
      overflowY: 'auto',
      gap: 14,
      padding: 7,
      marginTop: 14,
      marginBottom: 24,
    },
    establishmentCert: {
      width: 256,
      height: 120,
      borderRadius: 12,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      cursor: "pointer"
    },
  })
);

export default useStyles;

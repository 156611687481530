import { useTranslation } from "react-i18next";

import { useAppSelector } from '../../redux/hooks';

import { TerminologyProps } from "./props";
import { TerminologyType } from "../../models/enum/terminology-type";
import { Terminology } from '../../models/account/terminology';
import { TerminologyForm } from "../../models/enum/terminology-form";


export default function useTerminology(props: TerminologyProps) {
  
  const {
    type,
    form
  } = props;
  
  const { t } = useTranslation(["general"]);
  const accountSettings = useAppSelector((state) => state.scheduler.account);
  
  function getDefaultTerminology(terminologyType: TerminologyType) {
    switch (terminologyType){
      case TerminologyType.Certification:
        return new Terminology({
          terminologyType:   TerminologyType.Certification,
          singularForm: t('Certification'),
          pluralForm: t('Certifications'),
        })
    }
  }
  
  function getTerminology() {
    const terminology = accountSettings.terminologies?.find(t => t.terminologyType === type)
      ?? getDefaultTerminology(type);
    return form === TerminologyForm.Plural ? terminology.pluralForm : terminology.singularForm
  }

  return getTerminology();
}
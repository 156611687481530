import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepRoot: {
            paddingRight: 10,
            marginTop: 18,
            maxWidth: 1084,

            [theme.breakpoints.down("sm")]: {
                maxWidth: 272,
                marginTop: 8,
            },
        },
        stepContainer: {
            maxWidth: 938,
            display: 'flex',

            [theme.breakpoints.down("sm")]: {
                maxWidth: 272,
                display: 'initial',
            },
        },
        appointmentInfoContainer: {
            display: "flex",
            alignItems: "flex-start",
            width: 606,
            minHeight: 243,
            borderRadius: 6,
            boxShadow: "2px 2px 12px 5px rgba(235, 235, 235, 0.46);",
            marginBottom: 16,
            [theme.breakpoints.down("sm")]: {
                height: "auto",
                width: 272,
                flexDirection: "column",
                backgroundColor: "#FFF",
                alignItems: "start",
                padding: 16,
                gap: 16
            },
        },
        appointmentInfo: {
            width: 373,
            minHeight: 157,
            marginTop: 38,
            marginBottom: 37,
            marginLeft: 31,
            marginRight: 131,
            paddingBottom: 11,
            paddingRight: 0
        },
        customerInfoContainer: {
            display: "flex",
            alignItems: "flex-start",
            width: 323,
            minHeight: 243,
            borderRadius: 6,
            boxShadow: "2px 2px 12px 5px rgba(235, 235, 235, 0.46);",
            marginBottom: 16,
            [theme.breakpoints.down("sm")]: {
                height: "auto",
                width: 272,
                flexDirection: "column",
                backgroundColor: "#FFF",
                alignItems: "start",
                padding: 16,
                gap: 16
            },
        },
        customerInfo: {
            width: 280,
            minHeight: 157,
            marginTop: 38,
            marginBottom: 37,
            marginLeft: 22,
            marginRight: 24,
        },
        customerCommentContainer: {
            height: 'auto',
            marginBottomm: 24,
            [theme.breakpoints.down("sm")]: {
                height: "auto",
                width: 200,
            },
        },
        infoTitle: {
            ...theme.typography.txtBody1,
            color: theme.colors.grey6,
            fontWeight: 700,
            marginBottom: 5,
            
            [theme.breakpoints.down("sm")]: {
                width: 230,
            },
        },
        title: {
            ...theme.typography.header4,
            color: theme.colors.grey6,
            fontWeight: 700,
            marginBottom: 17,
            textAlign: 'center',

            [theme.breakpoints.down("sm")]: {
                width: 272,
            },
        },
        infoText: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey5,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        commentText: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey5,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'normal',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        infoPriceText: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey5,
            lineHeight: '20px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        infoServiceName: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey5,
            fontWeight: 700,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        appointmentInfoDateContainer: {
            display: 'flex',
            flexDirection: 'row',
            gap: 5,
            marginTop: 5,
            marginBottom: 24,
            alignItems: 'flex-end'
        },
        serviceInfo: {
            width: 373,
            marginBottom: 5,

            [theme.breakpoints.down("sm")]: {
                maxWidth: 272,
                display: 'grid',
                gap: 5
            },
        },
        serviceInfoRow: {
            width: 373,
            display: 'flex',

            [theme.breakpoints.down("sm")]: {
                maxWidth: 272,
            },
        },
        serviceInfoColumn: {
            width: 180,
            display: 'flex',
            gap: 15,

            [theme.breakpoints.down("sm")]: {
                maxWidth: 136,
            },
        },
        timeContainer: {
            width: 150,
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 5,

            [theme.breakpoints.down("sm")]: {
                maxWidth: 200,
            },
        },
        specialistContainer: {
            width: 370,
            display: 'flex',
            justifyContent: 'flex-start',
            gap: 5,

            [theme.breakpoints.down("sm")]: {
                maxWidth: 200,
            },
        },
        serviceInfoTimeWithSpecialistContainer: {
            [theme.breakpoints.down("sm")]: {
                display: 'grid',
                gap: 5,
                marginLeft: 5,
                marginTop: 5,
            },
        },
        cancellationContainer: {
            width: 350,
            marginTop: 24
        },
        cancellationTitle: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey6,
            fontWeight: 700,
            marginBottom: 5
        },
        cancellationText: {
            ...theme.typography.txtBody2,
            color: theme.colors.grey5
        },
        cancellationLink: {
            ...theme.typography.txtBody2,
            color: theme.colors.clearMain,
            fontWeight: 700,
            cursor: 'pointer'
        },
        cancellationTextContainer: {
            marginBottom: 24,
            [theme.breakpoints.down("sm")]: {

            },
        },
        line: {
            color: theme.colors.grey5,
            marginBottom: 24,
            [theme.breakpoints.down("sm")]: {
                width: 230,
                marginLeft: -10
            },
        },
    })
);

export default useStyles;
